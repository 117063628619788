/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, SeparateLine, SeparateLineWrap, FullmapCover, ContactForm, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"David Klema - Elektromontážní práce - Kontakt"} description={"Kontakt"} seoTitle={"Profesionální komunikace se zákazníkem je naší prioritou "}>
        <SiteHeader set="w3paydrlcin" currentLanguage={undefined} />

        <Column className="css-2ii13s css-kntedb --style2 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/3daae59f40c0485b8e9e3246b141bc5b_s=3000x_.jpg);
    }
  
background-position: 71% 18%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" style={{"maxWidth":""}} anim={"6"} animS={"5"}>
              
              <Subtitle className="subtitle-box fs--26 w--600 swpf--uppercase ls--50" content={"<span style=\"color: white;\">David Klema</span>"}>
              </Subtitle>

              <Title className="title-box fs--43 w--500 lh--1" content={"Profesionální komunikace se zákazníkem je naší prioritou&nbsp;<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingBottom":0,"paddingTop":0}} name={"gdt4c1jpaoa"} layout={"l3"}>
          
          <SeparateLineWrap className="--center" style={{"paddingBottom":10,"paddingTop":11}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60" style={{"paddingTop":7}} name={"fny3cm4qce"} layout={"l5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 flex--stretch" style={{"marginTop":13}} columns={"2"}>
            
            <ColumnWrapper >
              
              <FullmapCover style={{"overflow":"hidden","borderRadius":"6px"}} place={"David Klema"} zoom={"15"}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Jméno","type":"text","required":true,"placeholder":"Zadejte Vaše jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"o031i8mz32"} layout={"l3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingBottom":0,"paddingTop":0}} layout={"l1"} name={"dhnb5wf0a0v"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"paddingTop":21}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"David Klema - Elektromontážní práce"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"Kontakt"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: var(--white); font-weight: bold;\">Tovární 2446/2\n<br>Karviná Nové - Město<br>735 06<br>+420 739 294 592<br>davidklema@seznam.cz<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}